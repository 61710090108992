/**
 * c2c-广告
 */
import { create } from 'zustand'

import { createTrackedSelector } from 'react-tracked'
import { subscribeWithSelector, devtools } from 'zustand/middleware'
import { getCodeDetailListBatch } from '@/apis/common'
import produce from 'immer'
import { adCodeDictionaryEnum } from '@/constants/c2c/advertise'

type IStore = ReturnType<typeof getStore>

function getStore(set, get) {
  return {
    adCodeDictionary: {},
    getAdCodeDictionary: async () => {
      const res = await getCodeDetailListBatch(Object.values(adCodeDictionaryEnum))
      const resMapped = Object.values(adCodeDictionaryEnum).reduce((prev, curr, index) => {
        const processed = res[index].reduce((prev, cur) => {
          prev[cur.codeVal] = cur
          return prev
        }, {})
        prev[curr] = processed
        return prev
      }, {})
      set(
        produce((draft: IStore) => {
          draft.adCodeDictionary = resMapped
        })
      )
    },
  }
}

const baseC2CAdvertiseStore = create(devtools(subscribeWithSelector(getStore), { name: 'c2c-advertise-store' }))
const useC2CAdvertiseStore = createTrackedSelector(baseC2CAdvertiseStore)

export { useC2CAdvertiseStore, baseC2CAdvertiseStore }
